<template>
  <div>
    <div class="live">
      <div class="title d-flex align-center"><span></span>直播列表</div>
      <ul class="live-list">
        <li class="live-item" v-for="(item, indes) in liveList" :key="indes">
          <!-- <div class="live-active d-flex align-center" v-show="indes == 1">
            <img class="active-img" src="../../assets/img/live.png" alt="" />
            <div class="live-time">今天 19:30-21:00</div>
            <div class="live-title">
              2020下半年教师资格《小学教育教学知识》真题解析(一)
            </div>
            <div class="status d-flex align-center">
              直播中
              <img
                class="status-img"
                src="../../assets/img/live_z.png"
                alt=""
              />
            </div>
          </div> -->
          <div class="live-name">
            {{ item.name }}
          </div>
          <div class="live-bottom d-flex align-center">
            <div class="live-second">科目：{{ item.subjectName }}</div>
            <div
              class="live-btn d-flex align-center justify-center cursor"
              @click="golivePlay(item.id)"
            >
              前去查看
            </div>
          </div>
        </li>
        <div
          v-if="liveList == ''"
          class="kong d-flex align-center flex-column justify-center"
        >
          <!-- <img class="kong-img" src="../../assets/img/kong.png" alt="" /> -->
          <div class="kong-txt">暂无课程</div>
        </div>
      </ul>
    </div>
    <div class="start">
      <div class="head">
        <div class="title d-flex align-center"><span></span>录播列表</div>
        <ul class="tab d-flex">
          <li
            class="tab-item d-flex align-center cursor"
            @click="selectTab(0)"
            :class="{ tab_active: tabIndex == 0 }"
          >
            全部
          </li>
          <li
            class="tab-item d-flex align-center cursor"
            v-for="(item, index) in tabList"
            :key="index"
            @click="selectTab(item.id)"
            :class="{ tab_active: tabIndex == item.id }"
          >
            {{ item.name }}
          </li>
        </ul>
        <!-- <ul class="tabs d-flex justify-end">
          <li class="tabs-item cursor tabs_active">全部</li>
          <li class="tabs-item cursor">免费</li>
          <li class="tabs-item cursor">购课</li>
        </ul> -->
      </div>
      <ul class="list" v-if="coursesList != '' || coursesList != null">
        <li
          class="item d-flex align-center"
          v-for="(items, idx) in coursesList"
          :key="idx"
        >
          <div class="order">
            <img src="../../assets/img/live_head.png" alt="" />
          </div>
          <div class="detail d-flex align-center">
            <div class="detail-title">
              {{ items.name }}
            </div>
            <div class="detail-class">
              科目：{{ items.subjectName }}
            </div>
            <div class="detail-num">共{{ items.lessons }}节</div>
            <div
              class="detail-btn d-flex align-center justify-center cursor"
              @click="goRecording(items.id)"
            >
              听课学习
            </div>
          </div>
        </li>
      </ul>
      <div
        v-if="coursesList == '' || coursesList == null"
        class="kong d-flex align-center flex-column justify-center"
      >
        <img class="kong-img" src="../../assets/img/kong.png" alt="" />
        <div class="kong-txt">暂无课程</div>
      </div>
      <v-pagination
        v-show="total > 7"
        :total="total"
        :current-page="current"
        :display="display"
        @pagechange="pagechange"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import pagination from "@/components/paginationUser";
export default {
  data() {
    return {
      total: 0, // 记录总条数
      display: 7, // 每页显示条数
      current: 1, // 当前的页数
      liveList: "",
      coursesList: "",
      tabList: "",
      tabIndex: 0,
      courseSign: "",
      liveSign: "",
      popupNum: 0, //弹窗次数,
      isReady:false
    };
  },
  components: {
    "v-pagination": pagination
  },
  mounted() {
    this.getLivelist();
    this.getCourseslist();
    this.getAllMajor();
    this.popupNum = 0;
  },
  methods: {
    goRecording(id) {
      this.$router.push({
        name: `recording`,
        query: {
          id: id
        }
      });
    },
    golivePlay(id) {
      this.$router.push({
        name: `livePlay`,
        query: {
          id: id
        }
      });
    },
    pagechange(currentPage) {
      console.log(currentPage); //该参数就是当前点击的页码数
      // ajax请求, 向后台发送 currentPage, 来获取对应的数据
      this.current = currentPage;
      this.getCourseslist();
      const currentY =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollAnimation(currentY, 0);
    },
    scrollAnimation(currentY, targetY) {
      // 获取当前位置方法
      // 计算需要移动的距离
      let needScrollTop = targetY - currentY;
      let _currentY = currentY;
      setTimeout(() => {
        // 一次调用滑动帧数，每次调用会不一样
        const dist = Math.ceil(needScrollTop / 10);
        _currentY += dist;
        window.scrollTo(_currentY, currentY);
        // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
        if (needScrollTop > 10 || needScrollTop < -10) {
          this.scrollAnimation(_currentY, targetY);
        } else {
          window.scrollTo(_currentY, targetY);
        }
      }, 1);
    },
    //直播课包
    async getLivelist(params) {
      let _this = this;
      // let data = _this.id
      const res = await this.$ajaxRequest('get', 'myLives')
      console.log(res.data.list)
      _this.liveList = res.data.list;
      if (res.data.isSign == 0 && _this.popupNum == 0) {
        _this.popup();
        _this.popupNum = 1;
      }
    },
    //录播课包
    async getCourseslist(params) {
      let _this = this;
      let data = {
        pageNum: _this.current,
        pageSize: _this.display,
        majorId: _this.tabIndex
      }
      const res = await this.$ajaxRequest('post', 'myCourses', data)
      _this.coursesList = res.data.pageVo.list;
      _this.total = res.data.pageVo.totalRows;
      if (res.data.isSign == 0 && _this.popupNum == 0) {
        _this.popup();
        _this.popupNum = 1;
      }
    },
    //专业列表
    async getAllMajor(params) {
      let _this = this;
      const res = await this.$ajaxRequest('post', 'getAllMajor')
      this.tabList = res.data;
    },

    selectTab(id) {
      if (this.tabIndex != id) {
        this.tabIndex = id;
        this.getCourseslist();
      }
    },
    // 弹窗
    popup() {
      this.$confirm("您有课程未领取", "领取课程", {
        showCancelButton: false,
        showClose: false,
        confirmButtonText: "去领取",
        type: "warning"
      }).then(res => {
        this.$router.push("myOrder");
      });
    }
  }
};
</script>

<style lang="less" scoped>
.kong {
  margin-top: 50px;
  .kong-img {
    height: 210px;
    width: 280px;
  }
  .kong-txt {
    font-size: 20px;
    color: #999999;
    margin-top: 30px;
  }
}
.tab_active {
  border-color: #5f2eff;
  background: #5f2eff;
  color: #ffffff !important;
}
.tabs_active {
  border-color: #5f2eff !important;
}
.live {
  width: 1000px;
  height: 320px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-radius: 6px;
  margin-bottom: 20px;
  padding: 25px 36px 0 36px;
  box-sizing: border-box;
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #444444;
    span {
      width: 4px;
      height: 24px;
      background: #5f2eff;
      border-radius: 2px;
      margin-right: 12px;
      display: inline-block;
    }
  }
  .live-list {
    height: 241px;
    margin-top: 20px;
    overflow-y: auto;
    border-top: 2px solid #e7e8ef;
    .live-item {
      border-bottom: 1px solid #e7e8ef;
      margin-right: 10px;
      padding: 20px 0 15px 16px;
      box-sizing: border-box;
      .live-active {
        margin-bottom: 15px;
        .active-img {
          width: 17px;
          height: 12px;
        }
        .live-time {
          font-size: 14px;
          color: #5f2eff;
          margin-left: 6px;
        }
        .live-title {
          font-size: 14px;
          color: #5f2eff;
          margin-left: 29px;
        }
        .status {
          font-size: 14px;
          color: #f53444;
          margin-left: 19px;
          .status-img {
            width: 20px;
            height: 14px;
            margin-left: 14px;
          }
        }
      }
      .live-name {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 16px;
      }
      .live-bottom {
        .live-first {
          font-size: 14px;
          color: #888888;
        }
        .live-second {
          font-size: 14px;
          color: #888888;
        }
        .live-btn {
          width: 80px;
          height: 22px;
          border-radius: 11px;
          background-image: linear-gradient(176deg, #406bff 0%, #5f2eff 100%);
          font-size: 12px;
          color: #ffffff;
          margin-left: auto;
        }
      }
    }
  }
}
.start {
  width: 1000px;
  height: 750px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-radius: 6px;
  padding: 33px 36px 36px 36px;
  box-sizing: border-box;
  .head {
    border-bottom: 1px solid #e7e8ef;
    width: 100%;
    padding: 33px 0 6px 0;
    box-sizing: border-box;
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #444444;
      span {
        width: 4px;
        height: 24px;
        background: #5f2eff;
        border-radius: 2px;
        margin-right: 12px;
        display: inline-block;
      }
    }
    .tab {
      margin-top: 24px;
      margin-bottom: 23px;
      .tab-item {
        height: 26px;
        border: 1px solid #dddddd;
        border-radius: 13px;
        padding: 0px 21px;
        font-size: 16px;
        color: #999999;
        margin-right: 18px;
      }
    }
    .tabs {
      margin-left: auto;
      .tabs-item {
        font-size: 16px;
        color: #999999;
        padding-bottom: 6px;
        border-bottom: 2px solid transparent;
        margin-right: 19px;
      }
    }
  }
  .list {
    .item {
      height: 69px;
      .order {
        width: 16px;
        height: 16px;
        position: relative;
        margin-left: 16px;
        margin-right: 30px;
        img {
          position: relative;
        }
        &::before {
          content: "";
          display: block;
          height: 69px;
          width: 2px;
          background: #f4f0ff;
          position: absolute;
          top: 8px;
          left: 7px;
        }
      }
      .detail {
        position: relative;
        box-sizing: border-box;
        border-bottom: 1px solid #f6f9ff;
        width: 100%;
        height: 100%;
        .detail-title {
          width: 268px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 18px;
          color: #333333;
        }
        .detail-class {
          font-size: 14px;
          color: #888888;
          margin-left: 58px;
        }
        .detail-num {
          width: 150px;
          font-size: 14px;
          color: #888888;
          margin-left: auto;
        }
        .detail-btn {
          width: 80px;
          height: 22px;
          border: 1px solid #5f2eff;
          border-radius: 11px;
          font-size: 12px;
          color: #5f2eff;
        }
      }
    }
    :last-child {
      .order::before {
        display: none;
      }
    }
  }
}
</style>
